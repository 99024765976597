@import "../scss/variables";


.header {
	padding-top: .25rem;
	padding-bottom: .25rem;
	background: linear-gradient(120deg, #0f75ff 60%, #9d37f6 100%);
	width: 100%;
	z-index: 100;
	box-shadow: none;
	background: $white;
	border-bottom: 1px solid $border;
}

.nav-search {
	padding-right: 15px !important;
	padding-top: 5px !important;
	.form-control.header-search {
		background: $white;
		border: 1px solid $border;
		border-radius: 3px;
		color: #24273c;
		padding-left: 20px;
		padding-right: 0;
		margin-right: -6px;
		width: 190px;
		border-bottom-right-radius: 0;
		border-top-right-radius: 0;
		border-right: 0;
	}
}

.form-inline {
	.form-control {
		background: $white;
		border: 1px solid $border !important;
		border-radius: 3px;
		color: #24273c;
		padding-left: 20px;
		padding-right: 0;
		margin-right: -6px;
		width: 200px;
		border-bottom-right-radius: 0;
		border-top-right-radius: 0;
		border-right: 0;
		&:focus,
		&:hover {
			border: 1px solid $border !important;
		}
	}
	.btn {
		border-radius: 0;
		border-color: transparent;
		border-bottom-right-radius: 3px;
		border-top-right-radius: 3px;
	}
	.form-control::-webkit-input-placeholder {
		color: #8489a4;
	}
}

.nav-search {
	padding-right: 15px !important;
	padding-top: 5px !important;
}

.navbar-nav .input-icon-addon {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 8px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	min-width: 2.5rem;
	pointer-events: none;
	color: $white;
}

.form-control::-webkit-input-placeholder {
	color: #8489a4;
}

// .nav-link.navsearch-icon {
// 	color: #374254;
// }

.navbar .form-inline .btn {
	border-radius: 0;
	padding: 5px 15px 6px 15px;
	border: 2px solid #9d37f6;
	box-shadow: none;
	border-bottom-right-radius: 3px;
	border-top-right-radius: 3px;
}

@media (min-width: 1024px) {
	.form-inline .form-control {
		width: 180px;
	}
}

@media (max-width: 1024px) {
	body {
		&.search-show:before,
		&.sidebar-show:before {
			content: '';
			position: fixed;
			left: 0;
			right: 0;
			width: 100%;
			height: 100%;
			background-color: $black;
			opacity: .6;
			z-index: 891;
		}
		&.search-show {
			.header {
				z-index: 892;
			}
			overflow: hidden;
		}
		&.sidebar-show {
			overflow: hidden;
		}
	}
}

@media (max-width: 991px) {
	body.search-show .form-inline .nav-search {
		display: block;
	}
	.form-inline .nav-search {
		position: absolute;
		z-index: 892;
		display: none;
	}
	.search-show .nav-search {
		padding: 12px !important;
		left: 0;
		top: 0;
		right: 0;
		width: 100%;
	}
	.form-inline .nav-search {
		display: none;
	}
	body.search-show .form-inline .nav-search {
		display: block;
	}
	.form-inline .nav-search {
		position: absolute;
		z-index: 892;
		display: none;
		.btn {
			margin-top: 0 !important;
			border-radius: 0 3px 3px 0;
		}
		position: absolute;
		right: 24px;
		z-index: 892;
		display: none;
		.form-control {
			float: left;
			width: calc(100% - 39px);
			display: block;
		}
		.btn {
			margin-top: 0;
			border-radius: 0 3px 3px 0;
		}
	}
}

// @media (max-width: 414px) {
// 	.nav-link.navsearch-icon {
// 		font-size: 1.125rem;
// 		margin-top: 12px;
// 		padding-left: 10px;
// 	}
// }

// @media (min-width: 414px) and (max-width: 1366px) {
// 	.nav-link.navsearch-icon {
// 		font-size: 20px;
// 		margin-top: 12px;
// 		padding-left: 10px;
// 	}
// }

.header-search {
	padding: 0;
}

.nav-link.icon i {
	color: #2c2c2c;
}

.app-content .page-header {
	margin: 1.5rem 0 1.5rem 0;
	padding: .75rem 1.75rem;
	border: 1px solid $border;
	background: 0 0;
	min-height: 0;
	background: $white;
	border-radius: 3px;
}

@media (max-width: 480px) {
	.app-content .page-header {
		padding: .75rem .75rem !important;
	}
}

.error-img {
	background: #f6f7fb;
	.text-dark {
		color: #2c2c2c !important;
	}
	&:before {
		background: #f6f7fb;
	}
}

.highlight pre {
	background-color: #efeef7 !important;
}

.user-tabs ul li {
	// &:first-child {
	// 	border-left: 0;
	// }
	a {
		padding: 4px 15px;
		font-size: 15px;
		margin: 2px;
		display: block;
		border-radius: 4px;
	}
	// border-left: 2px solid $border;
}

@media (max-width: 568px) {
	.user-tabs ul li {
		a {
			width: 100%;
		}
		width: 100%;
		display: contents;
		text-align: center;
	}
}

.mail-option .btn {
	box-shadow: none;
}

.timelineleft>li>.timelineleft-item {
	box-shadow: none !important;
	border: 1px solid $border;
	border-radius: 4px;
}

.btn-group-xs>.btn,
.btn-xs {
	font-size: .7rem;
	line-height: 17px;
	padding: .02rem .5rem;
}

#count-down-1 {
	margin: 20px 0;
	.clock-presenter {
		height: 80px;
		padding: 0;
		text-align: center;
		.digit {
			margin-top: 20px;
			font-size: 2rem;
			line-height: 40px;
			height: 40px;
			padding: 0 7px;
			display: inline-block;
			overflow: hidden;
			text-align: center;
			position: relative;
			cursor: default;
			background: $white;
			color: #2c2c2c;
			border-radius: 3px;
		}
		.note {
			position: relative;
			margin-bottom: 0;
			cursor: default;
			font-size: 16px;
			opacity: .7;
		}
	}
}

@media (max-width: 992px) {
	#count-down-1 .clock-presenter .digit {
		font-size: 42px;
	}
}

@media (max-width: 767px) {
	#count-down-1 {
		.clock-presenter {
			width: 50%;
			float: left;
			.digit {
				font-size: 36px;
			}
		}
		.hours_dash {
			border-right: none;
		}
	}
	.header-brand-img {
		margin-top: 7px !important;
		float: left;
	}
	
}

.modal-body .select2-container {
	width: 100% !important;
}

.richText .richText-editor {
	height: 200px !important;
}

@media (min-width: 576px) {
	.modal-dialog {
		width: 800px;
	}
}

@media (max-width: 575.98px) {
	.app-content .side-app {
		padding: 38px 10px 0 10px !important;
	}
}

.dash3-counter .counter-icon {
	border: 0;
}

.mail-option {
	.btn-group a.btn,
	.chk-all {
		background: 0 0;
	}
}

.header-brand-img {
	width: auto;
	height: auto;
	vertical-align: middle;
}

@media (max-width: 495px) {
	.nested-media {
		.media-body {
			padding-top: 5px;
			margin-left: 0px;
		}
		.media {
			display: block !important;
		}
	}
}

.media.media-lg img {
	width: 70px;
	height: 70px;
}

*::-ms-backdrop,
img[src*=".svg"] {
	width: 100%;
}

.dark-logo {
	display: none;
}

@media (max-width: 767px) {
	.header-right .responsive-navbar .navbar-collapse {
		position: fixed;
		left: 0;
		background: #fff;
		width: 100%;
		margin-top: 118px;
		border-bottom: 1px solid $border;
		border-top: 1px solid $border;
		padding: 3px;
	}
	.navbar-toggler:focus {
		box-shadow: none;
	}
}

@media (min-width: 768px) {
	.app-header1 .collapse:not(.show) {
		display: block;
	}
}

@media (max-width: 767px) {
	.header-right .responsive-navbar .navbar-collapse .dropdown-menu.dropdown-menu-arrow.dropdown-menu-end {
		left: 10px !important;
		right: 10px !important;
	}
	.header-right .responsive-navbar .dropdown {
		position: initial;
	}
	.header-right .responsive-navbar .navbar-collapse .dropdown-menu.dropdown-menu-arrow.dropdown-menu-end:before {
		display: none
	}
	.header-right .responsive-navbar .navbar-collapse .dropdown-menu.dropdown-menu-arrow.dropdown-menu-end:after {
		display: none
	}
}

@media (max-width: 414px) {
	.navbar-collapse .nav-link span {
		margin-top: 0;
	}
}

@media (max-width: 991px) {
	.country-language {
		display: none;
	}
}
.light-layout {
 	display: none;
}

.construction-image .header-setting-icon {
	position: absolute;
    left: auto;
    right: 50px;
    top: 50px;
	background-color: $white-1;
	border-radius: 50%;
}

@media (max-width: 767px) {
	.construction-image .header-setting-icon {
		left: auto;
		right: 20px;
		top: 10px;
	}
}

#individual , #group {
	.richText .richText-toolbar ul li a {
		border: 0;
	}
}
.mail-option .chk-all {
	margin: 5px 5px 5px 0
}
.card.card-collapsed {
	border-radius: 3px;
	.card-header {
		border-bottom: 0;
	}
}



/*----- Admin Dashboard Sidemenu Styles Start ----- */

@media (min-width: 768px) {
	.app {
		&.sidenav-toggled {
			.app-content {
				margin-left: 0;
			}
			.app-sidebar {
				left: -230px;
			}
			.app-sidebar__overlay {
				visibility: hidden;
			}
		}
		&.sidebar-mini {
			.footer {
				margin-left: 230px;
			}
			&.sidenav-toggled {
				.footer {
					margin-left: 60px;
				}
				.side-menu .slide .btn-light {
					display: none;
				}
			}
		}
	}
}

@media (max-width: 767px) {
	.app {
		overflow-x: hidden;
		.app-sidebar {
			left: -230px;
		}
		.app-sidebar__overlay {
			visibility: hidden;
		}
		&.sidenav-toggled {
			.app-content {
				margin-left: 0;
			}
			.app-sidebar {
				left: 0;
			}
			.app-sidebar__overlay {
				visibility: visible;
			}
		}
	}
}

.app-content {
	min-height: calc(100vh - 50px);
	margin-bottom: 0 !important;
	-webkit-transition: margin-left 0.3s ease;
	-o-transition: margin-left 0.3s ease;
	transition: margin-left 0.3s ease;
	.side-app {
		padding: 38px 30px 0 30px;
	}
}

.footer .container {
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	margin-right: auto;
	margin-left: auto;
}

@media (min-width: 768px) {
	.app-content {
		margin-left: 230px;
	}
}

@media (max-width: 767px) {
	.app-content {
		min-width: 100%;
	}
}

@media print {
	.app-content {
		margin: 0;
		padding: 0;
		background-color: $white;
	}
}

.light-text {
	font-weight: 300 !important;
}

.semibold-text {
	font-weight: 600 !important;
}

.line-head {
	padding-bottom: 10px;
	border-bottom: 1px solid #ddd;
}

/*----- Componant: Top Navigation Bar ----- */

.app-header1 {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background-color: $white;
	// z-index: 1030;
	// padding-right: 15px;
	padding-left: 230px;
	.input-icon.mt-1 {
		margin-top: 2px !important;
	}
}

@media (min-width: 768px) {
	.app-header1 {
		padding-right: 20px;
	}
}

@media print {
	.app-header1 {
		display: none;
	}
}

.app-header__logo {
	-webkit-box-flex: 1;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	color: $white;
	text-align: center;
	padding: 0 15px;
	font-size: 26px;
	font-weight: 400;
	line-height: 50px;
	&:focus,
	&:hover {
		text-decoration: none;
	}
}

@media (min-width: 768px) {
	.app-header__logo {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		display: block;
		width: 230px;
	}
}

.app-sidebar__toggle {
	font-family: fontAwesome;
	color: #676b7b;
	padding: 0 15px;
	font-family: fontAwesome;
	color: #676b7b;
	line-height: 2.5;
	-webkit-transition: background-color 0.3s ease;
	-o-transition: background-color 0.3s ease;
	transition: background-color 0.3s ease;
	&:before {
		content: "\f0c9";
		font-size: 21px;
	}
	&:focus,
	&:hover {
		text-decoration: none;
		color: #676b7b;
	}
}

@media (max-width: 767px) {
	.app-sidebar__toggle {
		-webkit-box-ordinal-group: 0;
		-ms-flex-order: -1;
		order: -1;
		float: right;
	}
}

.app-nav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

@media (min-width: 768px) {
	.app-nav {
		-webkit-box-flex: 1;
		-ms-flex: 1 0 auto;
		flex: 1 0 auto;
	}
}

.app-nav__item {
	display: block;
	padding: 15px;
	line-height: 20px;
	color: #3e4b5b;
	-webkit-transition: background-color 0.3s ease;
	-o-transition: background-color 0.3s ease;
	transition: background-color 0.3s ease;
	&:hover,
	&:focus {
		background: $black-1;
		color: #f6f6f6;
	}
}

.app-search {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-item-align: center;
	align-self: center;
	margin-right: 15px;
	padding: 10px 0;
}

@media (max-width: 480px) {
	.app-search {
		display: none;
	}
}

.app-search__input {
	border: 0;
	padding: 5px 10px;
	padding-right: 30px;
	border-radius: 2px;
	background-color: $white-8;
	-webkit-transition: background-color 0.3s ease;
	-o-transition: background-color 0.3s ease;
	transition: background-color 0.3s ease;
	&::-webkit-input-placeholder,
	&:-ms-input-placeholder,
	&::-ms-input-placeholder,
	&::placeholder {
		color: $black-4;
	}
}

.app-search__button {
	position: absolute;
	right: 0;
	top: 10px;
	bottom: 10px;
	padding: 0 10px;
	border: 0;
	color: $black-8;
	background: none;
	cursor: pointer;
}

.app-notification {
	min-width: 270px;
}

.app-notification__title {
	padding: 8px 20px;
	text-align: center;
	background-color: rgba(0, 150, 136, 0.4);
	color: #333;
}

.app-notification__footer {
	padding: 8px 20px;
	text-align: center;
	background-color: #eee;
}

.app-notification__content {
	max-height: 220px;
	overflow-y: auto;
	&::-webkit-scrollbar {
		width: 6px;
	}
	&::-webkit-scrollbar-thumb {
		background: $black-2;
	}
}

.app-notification__item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 8px 20px;
	color: inherit;
	border-bottom: 1px solid #ddd;
	-webkit-transition: background-color 0.3s ease;
	-o-transition: background-color 0.3s ease;
	transition: background-color 0.3s ease;
	&:focus,
	&:hover {
		color: inherit;
		text-decoration: none;
		background-color: #e0e0e0;
	}
}

.app-notification__message,
.app-notification__meta {
	margin-bottom: 0;
}

.app-notification__icon {
	padding-right: 10px;
}

.app-notification__message {
	line-height: 1.2;
}

.app-sidebar {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	padding-top: 60px;
	width: 230px;
	overflow: auto;
	background: $white;
	z-index: 111;
	box-shadow: none;
	color: #153039;
	transition: left 0.3s ease, width 0.3s ease;
	&::-webkit-scrollbar {
		width: 6px;
	}
	&::-webkit-scrollbar-thumb {
		background: $black-2;
	}
}

@media print {
	.app-sidebar {
		display: none;
	}
}

@media (max-width: 767px) {
	.app-sidebar__overlay {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 9;
	}
}

.app-sidebar__user {
	padding: 20px 10px;
	color: #e0e0e0;
	position: relative;
	border-right: 1px solid $border;
	.user-pro-body img {
		background: rgba(167, 180, 201, 0.2);
		z-index: 1000;
		position: inherit;
		padding: 4px;
		width: 60px;
		height: 60px;
	}
}

.user-info {
	color: #153039;
	text-align: center;
	h2 {
		font-size: 15px;
		margin: 0;
		font-weight: 600;
		margin-top: 10px;
	}
}

.clearfix::after {
	display: block;
	clear: both;
	content: "";
}

.user-info span {
	font-size: 12px;
	line-height: 30px;
}

.profile-img {
	position: absolute;
	top: -1px;
	right: 70px;
	display: block;
	border: 1px solid rgba(101, 84, 187, 0.2);
	border-radius: 50%;
	font-size: 10px;
	line-height: 22px;
	width: 22px;
	text-align: center;
	height: 24px;
	color: #153039;
	background: rgba(167, 180, 201, 0.2);
	&:hover {
		background: $white-6;
	}
}

.app-sidebar__user .user-pro-body {
	display: block;
	img {
		margin-bottom: 0 !important;
		display: block;
		margin: 0 auto 10px;
	}
	.u-dropdown {
		color: #97999f;
	}
	.user-info span.text-gray {
		color: #153039 !important;
	}
	.dropdown-menu {
		right: 0;
		width: 80%;
		left: 0;
		margin: 0 auto;
		-webkit-transform: none !important;
		-ms-transform: none !important;
		transform: none !important;
		top: 85px !important;
		li a {
			padding-left: 5px !important;
			padding-right: 10px !important;
			padding-top: 10px !important;
			padding: 10px !important;
		}
	}
}

.sidebar-mini.sidenav-toggled .app-sidebar {
	.user-info,
	.profile-img,
	.app-sidebar-footer {
		display: none;
	}
	.app-sidebar__user {
		padding: 20px 0 !important;
	}
	.side-menu h3 {
		display: none;
		display: none;
	}
	.user-pro-body img {
		width: 3rem;
		height: 3rem;
	}
}

@media (max-width: 767px) {
	.sidebar-mini.sidenav-toggled .app-sidebar {
		.user-info {
			display: block;
		}
		.profile-img {
			display: block;
			right: 78px;
		}
		.app-sidebar-footer {
			display: block;
		}
		.app-sidebar__user {
			padding: 20px 0 !important;
			margin-left: 10px;
		}
	}
	.app-sidebar__user .user-pro-body img,
	.sidebar-mini.sidenav-toggled .app-sidebar .user-pro-body img {
		width: 3rem;
		height: 3rem;
	}
}

.app-sidebar__user-avatar {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	margin-right: 15px;
}

.app-sidebar__user-name {
	&.text-sm {
		font-size: 13px;
	}
	font-size: 17px;
	line-height: 1.3;
	white-space: nowrap;
	overflow: hidden;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	margin-bottom: 0;
}

.app-sidebar__user-designation {
	white-space: nowrap;
	overflow: hidden;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	margin-bottom: 0;
}

.side-menu {
	margin-bottom: 0;
	padding: 0 15px 40px 20px;
	border-right: 1px solid $border;
	h3 {
		padding-left: 15px;
		color: #153039;
		text-transform: uppercase;
		letter-spacing: 0.5px;
		font-weight: bold;
		font-size: 11px;
		margin-bottom: 15px;
		margin-top: 15px;
	}
	.slide li a {
		position: relative;
	}
	li ul li a.active:before {
		display: none;
	}
}

/*
.side-menu .side-menu__icon{
	color: $white;
    height: 30px;
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    width: 30px !important;
    border-radius: 50%;
	margin-right:10px;
    
}
.side-menu li a.active:before{
	content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 5px;
    height: 100%;
    display: block;
}*/

.side-menu__item {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 13px;
	font-weight: 400;
	padding: 10px 0px;
	-webkit-transition: border-left-color 0.3s ease, background-color 0.3s ease;
	-o-transition: border-left-color 0.3s ease, background-color 0.3s ease;
	transition: border-left-color 0.3s ease, background-color 0.3s ease;
	color: #6c81a6;
}

.app-sidebar .mCSB_draggerContainer {
	right: -11px;
}

.side-menu__icon {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	width: 25px;
	line-height: 25px !important;
	text-align: center;
	border-radius: 3px;
	margin-right: 10px;
	font-size: 17px !important;
}

.side-menu__label {
	white-space: nowrap;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
}

.side-menu li ul li a {
	border-top: 0;
	border-bottom: 0;
}

.app-sidebar {
	.side-menu li a.active {
		background: none;
	}
	.slide-menu li.active > a {
		font-weight: 500;
	}
}

.slide-menu li {
	position: relative;
	&.active {
		&:before {
			content: "";
			position: absolute;
			width: 15px;
			height: 2px;
			background: $border;
			left: -20px;
			top: 15px;
			display: block;
		}
		&:after {
			content: "";
			position: absolute;
			width: 8px;
			height: 8px;
			left: -10px;
			top: 12px;
			display: block;
			border-radius: 50%;
		}
	}
	ul {
		li {
			font-size: 12px !important;
			font-weight: 300;
			border-bottom: 0px solid rgba(0, 0, 0, 0.03);
		}
		a {
			padding: 10px 10px 10px 65px;
		}
	}
}

.side-menu .slide-menu li a {
	font-size: 13px;
	font-weight: 400;
}

.slide ul li a {
	color: #153039;
	font-weight: 400;
}

.app-sidebar-footer {
	clear: both;
	display: block;
	color: $white;
	position: fixed;
	bottom: 0px;
	width: 228px;
	z-index: 999;
	border-top: 1px solid #e9ecf3;
	a {
		padding: 10px 0;
		text-align: center;
		width: 20%;
		color: $white;
		font-size: 15px;
		display: block;
		float: left;
		cursor: pointer;
		border-right: 1px solid #e9ecf3;
		border-bottom: 1px solid #e9ecf3;
		&:hover {
			background: rgba(255, 255, 255, 0.02);
		}
	}
}

.slide ul ul a {
	font-size: 1em !important;
	padding: 10px 10px 10px 25px !important;
}

.slide ul ul ul a {
    font-size: 1em !important;
    padding: 10px 10px 10px 40px !important;
}

.app-sidebar ul li a {
	color: #8492a6;
	font-weight: 500;
}

.slide.is-expanded {
	.slide-menu {
		// max-height: 100%;
		display: block;
	}
	.angle {
		-webkit-transform: rotate(90deg);
		-ms-transform: rotate(90deg);
		transform: rotate(90deg);
	}
}

.slide-menu {
	// max-height: 0;
	display: none;
	overflow: hidden;
	-webkit-transition: max-height 0.3ms ease;
	-o-transition: max-height 0.3ms ease;
	transition: max-height 0.3ms ease;
	padding: 0;
	font-size: 0.8rem !important;
	padding-left: 20px;
	margin-left: 12px;
	border-left: 1px solid $border;
}

.slide-item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 6px 7px;
	font-size: 13px;
	color: #6c81a6;
	z-index: 999 !important;
}

.app-sidebar .side-menu .slide-menu li:last-child {
	border-bottom: 0;
}

.slide-item .icon {
	margin-right: 5px;
}

.angle {
	-webkit-transform-origin: center;
	-ms-transform-origin: center;
	transform-origin: center;
	-webkit-transition: -webkit-transform 0.3s ease;
	transition: -webkit-transform 0.3s ease;
	-o-transition: transform 0.3s ease;
	transition: transform 0.3s ease;
	transition: transform 0.3s ease, -webkit-transform 0.3s ease;
	margin-right: 10px;
}

.dropdown-menu {
	&.dropdown-menu-end {
		left: auto !important;
		// right: 0 !important;
	}
}

.dropdown-item {
	.fa,
	.icon {
		vertical-align: middle;
	}
}

.app-title {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	background-color: #fff;
	margin: -30px -30px 30px;
	padding: 20px 30px;
	-webkit-box-shadow: 0 1px 2px $black-1;
	box-shadow: 0 1px 2px $black-1;
	h1 {
		margin: 0;
		font-size: 24px;
		font-weight: 400;
	}
	p {
		margin-bottom: 0;
		font-style: italic;
	}
}

@media print {
	.app-title {
		display: none;
	}
}

@media (max-width: 480px) {
	.app-title {
		margin: -15px -15px 15px;
		padding: 20px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-ms-flex-align: start;
		align-items: flex-start;
	}
}

@media (max-width: 480px) {
	.app-title p {
		display: none;
	}
}

.app-breadcrumb {
	margin-bottom: 0;
	text-align: right;
	font-weight: 500;
	font-size: 13px;
	text-transform: capitalize;
	padding: 0;
	text-align: left;
	padding: 0;
	background-color: transparent;
}

@media (min-width: 768px) {
	.sidebar-mini.sidenav-toggled {
		.app-sidebar__user-name,
		.app-sidebar__user-designation,
		.angle {
			display: none;
		}
		.app-sidebar__user-avatar {
			width: 30px;
			height: 30px;
		}
		.app-content {
			margin-left: 60px;
		}
		.app-sidebar {
			left: 0;
			width: 60px;
			overflow: hidden;
			&:hover {
				overflow: visible;
			}
		}
		.side-menu__item {
			// overflow: hidden;
			// padding: 10px 17px !important;
			&:hover {
				overflow: visible;
				.side-menu__label {
					opacity: 1;
				}
				+ .slide-menu {
					visibility: visible;
				}
			}
		}
		.side-menu__label {
			display: none;
		}
		.slide {
			&:hover {
				.side-menu__item {
					overflow: visible;
					border-left-color: #766cc5;
					// background: #e0e2e8;
					color: #111;
				}
				.side-menu__label {
					opacity: 1;
				}
				.slide-menu {
					max-height: 100vh;
					opacity: 1;
					visibility: visible;
					z-index: 10;
				}
			}
			.side-menu__label {
				border-bottom-right-radius: 0;
			}
		}
		.slide-menu {
			position: absolute;
			left: 60px;
			min-width: 180px;
			padding: 12px 0;
			opacity: 0;
			border-bottom-right-radius: 4px;
			z-index: 9;
			visibility: hidden;
			-webkit-transition: visibility 0.3s ease;
			-o-transition: visibility 0.3s ease;
			transition: visibility 0.3s ease;
		}
		// .side-menu {
		// 	padding-left: 0px;
		// 	padding-right: 0px;
		// }
	}
}

@media (max-width: 480px) {
	.app-breadcrumb {
		margin-top: 10px;
	}
}

.tile {
	position: relative;
	background: $white;
	border-radius: 3px;
	padding: 20px;
	-webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px $black-2;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px $black-2;
	margin-bottom: 30px;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

@media print {
	.tile {
		border: 1px solid #ddd;
	}
}

.sidenav-toggled .app-sidebar__user .avatar-md {
	width: 1.8rem;
	height: 1.8rem;
	line-height: 2rem;
	font-size: 1rem;
}

.doc-header {
	margin: 0 auto !important;
	text-align: center;
	padding: 21px 10px !important;
	display: table;
	.header-brand-img {
		margin-top: 0;
		margin-right: 0;
	}
}

.doc-sidebar {
	.side-menu__item1 {
		padding: 15px 15px 15px 20px;
		background: #d8dee6;
	}
	.side-menu__label1 {
		color: #153039;
		font-weight: 500;
	}
}

.app-sidebar .side-menu__item.active {
	background: none;
	color: #2e384d;
	&:hover {
		background: none;
		color: #2e384d;
		color: $primary;
	}
	color: $primary;
}

.side-menu__item:hover {
	color: $primary;
}

.app-sidebar {
	.side-menu__item.active i,
	.side-menu li ul li a:hover {
		color: $primary;
	}
}

.btn-light {
	color: #3d4e67 !important;
}

// submenus //
.slide .slide-menu .sub-slide .sub-slide-menu {
	display: none;
}

.slide .slide-menu .sub-slide .sub-slide-menu.open {
	display: block;
}

.slide .slide-menu .sub-slide .sub-slide-menu .sub-slide2 .sub-slide-menu2 {
	display: none;
}

.slide .slide-menu .sub-slide .sub-slide-menu .sub-slide2 .sub-slide-menu2.open {
	display: block;
}

.sub-slide.is-expanded .sub-angle {
	transform: rotate(90deg);
}

.sub-slide2.is-expanded .sub-angle2 {
	transform: rotate(90deg);
}
//


@media (min-width: 768px) {
	.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar {
	  width: 230px;
	  -webkit-transition: left 0.3s ease, width 0.3s ease;
	  transition: left 0.3s ease, width 0.3s ease;
	}
	.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .app-sidebar__user {
	  padding: 20px 10px !important;
	}
	.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .user-pro-body img {
	  width: 60px;
	  height: 60px;
	}
	.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .side-menu__item {
		position: relative;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		font-size: 13px;
		font-weight: 400;
		padding: 10px 0;
		-webkit-transition: border-left-color 0.3s ease, background-color 0.3s ease;
		-o-transition: border-left-color 0.3s ease, background-color 0.3s ease;
		transition: border-left-color 0.3s ease, background-color 0.3s ease;
		color: #6c81a6;
		transition: all;
		transition: 0.5s ease;
	}
	.sidebar-mini.sidenav-toggled.sidenav-toggled1 .side-menu__label {
	  opacity: 1;
	  background: transparent;
	}
	.sidebar-mini.sidenav-toggled .slideis-expanded .slide-menu.open {
	  display: block;
	  opacity: 1;
	  visibility: visible;
	  z-index: 10;
	}
	.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .user-info,
	.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .profile-img,
	.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .app-sidebar-footer {
	  display: block;
	}
	.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar__user-name,
	.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar__user-designation,
	.sidebar-mini.sidenav-toggled.sidenav-toggled1 .angle {
	  display: block;
	}
	.sidebar-mini.sidenav-toggled.sidenav-toggled1 .slide-menu {
	  position: relative;
	  left: 0;
	  min-width: 180px;
	  padding: 0;
	  opacity: 1;
	  visibility: visible;
	  padding: 0;
	  font-size: 0.8rem !important;
	  padding-left: 20px;
	  margin-left: 12px;
	  border-left: 1px solid rgba(3, 37, 108, 0.1);
	}
	.sidebar-mini.sidenav-toggled.sidenav-toggled1 .side-menu__label {
	  display: block;
	}
	.sidebar-mini.sidenav-toggled.sidenav-toggled1 .side-menu .slide.is-expanded .side-menu__item .side-menu__label {
	  color: $primary;
	}
	.sidebar-mini {
		&.sidenav-toggled.sidenav-toggled1 {
			.side-menu .slide .btn-light {
				display: block;
			}
		}
	}
  }


//
@media (min-width: 768px) {
	.responsive-logo .header-brand {
		display: none;
	}
}

@media (max-width: 767px) {
	.app-header1 {
		padding-left: 0px;
	}
}


@media (min-width: 768px) {
	.main-sidebar-header {
		width: 230px;
		left: 0;
		right: 0;
		top: 0;
		position: fixed;
		z-index: 9999;
		background: #fff;
		transition: left 0.3s ease, width 0.3s ease;
	}
}
.main-sidebar-header {
    display: flex;
    border-bottom: 1px solid $border;
    border-right: 1px solid $border;
    padding: 19px 17px;
    align-items: center;
    height: 62px;
}
.header-brand {
    margin: 0 auto;
    text-align: center;
}

@media (min-width: 768px) {
	.header-brand .desktop-logo {
		display: block;
	}
	.header-brand .desktop-dark, .header-brand .mobile-logo, .header-brand .mobile-logo-dark {
		display: none;
	}
	.sidenav-toggled .header-brand .desktop-dark, .sidenav-toggled .header-brand .desktop-logo {
		display: none;
	}
	.sidenav-toggled .header-brand .mobile-logo {
		display: block;
	}
	.sidenav-toggled .main-sidebar-header {
		width: 60px;
	}
	.sidenav-toggled .app-header1 {
		padding-left: 70px;
	}
	.sidenav-toggled1 .header-brand .desktop-dark, .sidenav-toggled1 .header-brand .mobile-logo {
		display: none;
	}
	.sidenav-toggled1 .header-brand .desktop-logo {
		display: block;
	}
	.sidenav-toggled1 .main-sidebar-header {
		width: 230px;
		transition: left 0.3s ease, width 0.3s ease;
	}

}
@media (max-width: 767px) {
	.main-sidebar-header {
		display: none;
	}
	.app-sidebar {
		z-index: 99;
	}
}

@media (max-width: 430px) {
	.navbar-collapse .nav-link.icon {
		margin: 5px 3px;
		width: 1rem;
	}
	.country-selector .nav-link {
		margin: 5px -10px 5px 0px;
	}
	.header .nav-item, .header .nav-link { 
		min-width: 1.7rem;
	}
	.header .nav-item .badge, .header .nav-link .badge {
		right: -8px !important;
		padding: 0.11rem 0.25rem !important;
		font-size: 10px !important;
	}
	.nav-unread.badge {
		width: 0.9rem !important;
    	height: 0.9rem !important;
	}
}

@media (min-width: 991px) {
	.app-sidebar__toggle {
		margin-right: 0.5rem;
	}
}

/*----- Admin Dashboard Sidemenu Styles End ----- */

  